import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../providers/auth.service';

@Component({
    selector: 'cdk-sidemenu-item',
    templateUrl: './sidemenu-item.component.html',
    styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent implements OnInit {

    @Input() menu;
    @Input() iconOnly: boolean;
    @Input() secondaryMenu = false;

    constructor(private authService: AuthService) { }

    ngOnInit() {
    }

    openLink() {
        this.menu.open = this.menu.open;
    }

    chechForChildMenu() {
        return (this.menu && this.menu.sub) ? true : false;
    }
    public checkRights(Right){
    // console.log('right',Right);
        return  this.authService.checkUserAssignedRights(Right);
         
        }
}
