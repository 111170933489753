<!-- <stbui-loading *ngIf="showLoading"></stbui-loading> -->
<mat-toolbar   class="mat-elevation-z4">  
	<button mat-icon-button (click)="sidenav.toggle();drawer.toggle();" *ngIf="matDrawerShow">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button> 
    <button mat-icon-button (click)="sidenav.toggle();" *ngIf="!matDrawerShow">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button>

    <span class="spacer"></span>

    <!-- <button  mat-icon-button (click)="searchOpen = !searchOpen" fxHide="true" [fxHide.gt-xs]="false">
        <i class="material-icons">search</i>
    </button>
    <cdk-search-bar [open]="searchOpen"></cdk-search-bar> -->

    <cdk-fullscreen></cdk-fullscreen>

    <!-- <cdk-toolbar-notification [notifications]="toolbarHelpers?.notifications"></cdk-toolbar-notification> -->

    <cdk-user-menu [currentUser]="toolbarHelpers?.currentUser"></cdk-user-menu>
    
     <!-- <button mat-icon-button (click)="sidebar.toggle();">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button> -->
    
</mat-toolbar>