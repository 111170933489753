<div tabindex="-1" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="small_popup">
        <div class="popup_header">
            <h4>Confirm Alert</h4>
        </div>
        <div class="popup_content delete_popup">
            <div class="space-15"></div>
            <div class="delete_warning">
                <div>
                    <i class="fa fa-trash-alt"></i>
                </div>
                <div>
                    <h5>Are you sure ?</h5>
                    <p>This action cannot be undone!</p>
                </div>
            </div>
            <div class="space-15"></div>

            <div class="row cancel_btn float-right">
                <div class="col-md-12 col-sm-12 no_padding">
                    <button (click)="onNo()" >Cancel</button>
                    <button class="primary_blue_btn" (click)="onYes()">Yes, Sure</button>
                </div>
            </div>
        </div>
    </div>
</div>
