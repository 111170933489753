import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helper-functions/jwt-interceptor';
import { ErrorInterceptor } from './helper-functions/http-error-handler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { AlertConformComponent } from './core/alert-conform/alert-conform.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ConformDialogComponent } from './conform-dialog/conform-dialog.component';




@NgModule({
  
  declarations: [
    AppComponent,
    ConformDialogComponent,
 
    
  ],
  entryComponents:[
    AlertConformComponent
  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
   
  ],
  providers: [
    HttpClient, 
    MatSnackBar,
    Overlay,
    MatDialog,
    FormBuilder,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
