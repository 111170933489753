import { environment } from "../../environments/environment";
export class EndPointConfig {
    public static getControllerUrl(controllerName: string, actionName?: string) {
        return this.getApiUrl() + '/' + controllerName + (actionName ? '/' + actionName : '');
    }
    public static getApiUrl() {
        return this.getServerUrl() + '/api/v1';
    }
    public static getServerUrl() {
        return environment.production ? 'https://api.deliverydesks.com' : 'https://api.deliverydesks.com';
    }

  //https://api.deliverydesks.com/
  //https://localhost:44383/
}
