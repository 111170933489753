export enum ERights {
    UserManagement = "5bb20bf7-30fe-473f-8967-009f1f18c5d0",
        Category = "5BB20BF7-30FE-473F-8967-339F1F18C5D0",
        SubCategory = "ab17ad4b-ae74-4d59-890c-78999e0cd080",
        VehicleType  = "2ce0adbf-6b33-4da9-aa7d-9308714ca558",
        Area  = "984beda8-e7fa-4074-9370-dc5fe99c599e",
        Cities = "43d288c9-63ae-4463-88b6-0deaa0156157",
        PaymentType  = "144d1464-78cd-4784-82ac-697e0f859141",
        OperationalTimming  = "3efa73ee-91f9-4e12-8c0c-47e816c2d7fc",
        Marchant  = "68ca7641-171e-4b68-8405-536e78bc9210",
        Driver  = "357abfeb-a0e7-47d9-b2bc-80eb3ca25600",
}