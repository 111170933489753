<mat-nav-list [style.maxHeight]="(menu.open)?'1200px':'48px'"   [ngClass]="{'secondaryMenu': secondaryMenu, 'primary': !secondaryMenu}">
    <mat-list-item *ngIf = "menu.link==false" (click)="menu.open = !menu.open" >
        <mat-icon matListIcon iconsmall  >{{menu.icon}} </mat-icon>
        <h3 matLine *ngIf="!iconOnly">{{ menu.name }} </h3>
        <mat-chip-list *ngIf="menu?.chip && !iconOnly">
            <mat-chip >{{menu?.chip?.value}} </mat-chip>
        </mat-chip-list>        
        <mat-icon *ngIf="chechForChildMenu()" class="sidenav-dropdown-indicator rotate " [ngClass]="{'indicateOpen':menu.open}"> expand_more</mat-icon>
    </mat-list-item>

    <mat-list-item *ngIf = "menu.link!=false" (click)="menu.open = !menu.open" [routerLink]="[menu.link]">
    	<mat-icon matListIcon iconsmall  >{{menu.icon}} </mat-icon>
        <h3 matLine *ngIf="!iconOnly">{{ menu.name }} </h3>
    </mat-list-item>

    <cdk-sidemenu-item *ngFor="let submenu of menu?.sub" [menu]="submenu" [iconOnly]="iconOnly" [secondaryMenu]="true"> </cdk-sidemenu-item>

</mat-nav-list>
