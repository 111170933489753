
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { EndPointConfig } from '../helper-functions/api-end-point';
import { AuthSessionService } from './auth-session.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user:any ={};
  headers: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient,
    private sessionService: AuthSessionService) {
    this.headers.set('Content-Type', 'application/json');
   }
 
  login(loginModal:any) {
    return this.http.post<any>(EndPointConfig.getControllerUrl('AdminAccounts', 'AdminLogin'), loginModal,{headers:this.headers})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user) {
          localStorage.setItem('key',user.data.accessToken);
        
          this.user = user
          // store user details and jwt in cookie
          this.sessionService.setCookie('currentUser', JSON.stringify(user), 1)
        }
        return user
      }))
  }
  public currentUser(): any {
    if (this.user) {
      
      this.user = JSON.parse(this.sessionService.getCookie('currentUser'));
      
    }
    return this.user
  }
 
  checkUserAssignedRights(rightId){
    if(rightId === ''){
      return true
    }
    else{
      if(this.currentUser() !== null){
        if(this.currentUser().data.rightsId.length > 0){
          return this.currentUser().data.rightsId.some(x=>x.id === rightId);
        }
      }
    }
    // return this.currentUser().data.rightsId.some(x=>x.id === rightId);
  }
  logout() {
    // remove user from local storage to log user out
    return new Promise( (resolve,reject) =>{
      this.sessionService.deleteCookie('currentUser')
      this.user = null
      resolve(this.user);
    })
    
  }
}
