import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { ToastService } from '../../providers/toast.service';

@Component({
  selector: 'app-alert-conform',
  templateUrl: './alert-conform.component.html',
  styleUrls: ['./alert-conform.component.scss']
})
export class AlertConformComponent implements OnInit {
  @Output()
  confirmDelete = new EventEmitter<boolean>();
  constructor(public dialogRef: MatDialogRef<AlertConformComponent>,
    private apiService: ApiService,private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public popUpdata:any) { }

  ngOnInit(): void {
  }

  onYes() {
    debugger;
    const activation = false;
         this.apiService.Get(`${this.popUpdata.controller}`,`${this.popUpdata.endPoint}/${this.popUpdata.data}/${activation}`).toPromise().then(() =>{
           this.toastService.showSuccessMessage(' Deleted Successfully');
           this.confirmDelete.emit(true);
           this.closePopup();
         }).catch(error =>{
           this.toastService.showErrorMessage('Error while deleting ');
         });  
}

onNo() {
    this.confirmDelete.emit(false);
    this.closePopup();
}
closePopup()
{
    this.dialogRef.close();
}
}
