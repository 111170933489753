import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AuthService } from '../providers/auth.service'
import { ToastService } from '../providers/toast.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private toastService: ToastService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('In ErrorInterceptor');
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        // this.authService.logout();
        // location.reload()
      }
      
      this.toastService.showErrorMessage(err.error.message);
      // const error = err.error.message || err.statusText
      return throwError(JSON.stringify(err.error))
    }))
  }
}
