
import { Injectable } from '@angular/core';

import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { EndPointConfig } from '../helper-functions/api-end-point';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
// private endPoint: EndPointConfig;

headers: HttpHeaders = new HttpHeaders();
getheaders: HttpHeaders = new HttpHeaders();

  constructor(private httpClient: HttpClient) { 
    this.headers.set('Accept', '*/*');
  }
  //////////////////// Basic CRUD///////////////////
  Put(controller,endPoint,obj){
 
   return  this.httpClient.post(`${EndPointConfig.getControllerUrl(`${controller}`, `${endPoint}`)}`,obj);
  }
  Post(controller,endPoint,obj:any){
   return  this.httpClient.post(`${EndPointConfig.getControllerUrl(`${controller}`, `${endPoint}`)}`,obj,{headers:this.headers});}

   Get(controller,endPoint){
    return  this.httpClient.get(`${EndPointConfig.getControllerUrl(`${controller}`, `${endPoint}`)}`,{headers:this.headers});
  }
  
   Delete(){
    return  this.httpClient.post('',{},{});
  }
  public HttpGetdetail(controller: string, action: string, id){
    return this.httpClient.get(`${EndPointConfig.getControllerUrl(`${controller}`, `${action}`)}`,{params:{Id:id},headers:this.headers});
  }
  ///////////////////////////////////////////////
}
